@import "../../scss/colors.scss";
@import "../../scss/repeat.scss";

.barCardAdress {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  /* or 138% */

  /* text/light */

  color: #808191;
}

.barCardName {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #11142d;
  margin-bottom: 4px;
}

.barCardImages {
  width: 87px;
  min-width: 87px;
  height: 87px;
  background-size: cover;
  border-radius: 8px;
  margin-right: 33px;
}
.barsCardMain {
  display: flex;
  flex-direction: row;
}
.barsCard {
  display: flex;
  flex-direction: row;
  color: #000;
  width: 100%;
  justify-content: space-between;
  padding-left: 32px;
  position: relative;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: #e4e4e4 solid 1px;
  align-items: center;

  &__topBlock:nth-child(1) {
    position: absolute;
    left: 0;
    @extend %button;
    width: fit-content;

    svg path,
    svg {
      transition: all 0.2s ease;
    }

    &:hover {
      svg path {
        fill: #fff;
      }
    }

    &:active {
      svg path {
        fill: #fff;
      }
    }
  }

  &__topBlock:nth-child(2) {
    width: 87px;
    height: 87px;
    background-color: rgba(228, 228, 228, 0.2);
    position: relative;
    border-radius: 8px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }
  div {
    word-wrap: break-word;
  }

  div:nth-child(1) {
    width: 20.5%;
  }

  div:nth-child(2) {
    width: 17%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    /* Color/Dark - Grey - Neutral/DEACTIVE */

    color: #808191;
  }

  div:nth-child(3) {
    width: 20.5%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    /* Color/Dark - Grey - Neutral/DEACTIVE */

    color: #808191;
  }

  div:nth-child(4) {
    width: 15.3%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    color: #450fd9;
  }

  div:nth-child(5) {
    width: 8.8%;
  }

  div:nth-child(6) {
    width: 8.4%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    background: linear-gradient(
        91.09deg,
        #fa36f8 1.51%,
        #b200e8 36.54%,
        #450fd9 101.76%
      ),
      #7fba7a;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-align: center;
  }

  div:nth-child(7) {
    width: 48px;

    button {
      @extend %button;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: $white;
      box-shadow: 0px 24px 43px -12px rgba(34, 60, 80, 0.4);
      margin-left: auto;

      &:hover {
        svg path {
          transition: all 0.2s ease;
          fill: $error;
        }
      }
    }
  }
}

.myClass {
  width: 8.8%;
  text-align: center;
  color: #ff754c;
  cursor: pointer;

  span {
    text-decoration: underline;
  }
}

.myClassButton {
  text-align: center;
  background-color: #fff;
  cursor: pointer;
  border-radius: 10px;
  width: 60px;
  height: 30px;
}
