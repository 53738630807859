.container {
  // width: 70vw;
  border-radius: 24px;
  padding: 24px;
  box-shadow: 0px 16px 80px 17px rgba(227, 230, 236, 0.86);
  margin-bottom: 50px;
}

.heading {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 30px;
}

.statisticBlock {
  border: 1px solid #e4e4e4;
  padding: 24px;
}

.statisticsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.headingWrapper {
  display: flex;
  align-items: center;
  width: 20vw;
}

.number {
  margin-top: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 40px;
  padding-bottom: 5px;
  border-bottom: 2px solid #b200e8;
}

.title {
  margin-left: 10px;
  font-family: "Inter", sans-serif;
}
