@import "../../scss/repeat.scss";
.ownCase {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  border-bottom: #e4e4e4 solid 1px;
}
.ownLogin {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #b2b3bd;
  padding-bottom: 14px;

  border-bottom: #e4e4e4 solid 1px;
}
.ownPlace {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #808191;
}
.ownPlaceBtn {
  padding: 8px 18px;
  background: #450fd9;
  border-radius: 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  border-color: #450fd9;
}
.globalContainerWrap {
  display: flex;
  flex-direction: "row";
  column-gap: 50px;
}
.containerOwners {
  @extend %scrollList;
  width: 25vw;
  padding: 32px;
  height: 65vh;
  border-radius: 24px;
  box-shadow: 0px 16px 16px 17px rgba(227, 230, 236, 0.86);
}
.container {
  width: fit-content;
  padding: 32px;
  border-radius: 24px;
  box-shadow: 0px 16px 16px 17px rgba(227, 230, 236, 0.86);
}

.inputHeading {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: medium;
  margin-bottom: 10px;
  margin-left: 10px;

  color: #b2b3bd;
}

.form {
  width: 70%;
}

.inputWrapper {
  margin-bottom: 20px;
  position: relative;
}

.input {
  width: 100%;
  display: inline-block;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 18px;
  border-radius: 8px;
  margin-bottom: 10px;

  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;

  background-color: rgba(227, 230, 236, 0.86);

  border: 2px solid transparent;

  &:focus {
    border: 2px solid #b200e8;
    background-color: #fff;
  }
}

.form {
  width: 300px;
}

.errorText {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: red;
  position: absolute;
}

.blockHeading {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  margin-bottom: 32px;
}
