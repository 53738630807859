.button {
  color: white;
  font-family: "Inter", sans-serif;

  width: 100%;
  cursor: pointer;
  margin-top: 10%;
  background-color: #450fd9;
  border: none;
  padding: 18px;
  border-radius: 8px;

  &__disabled {
    background-color: #cfcfcf;
  }
  &__loading {
    background-color: #cfcfcf;
    padding: 5px;
  }

  &:active {
    opacity: 0.7;
    box-shadow: 0 1px #6c5dd3;
    transform: translateY(1px);
  }
}
