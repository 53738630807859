.button {
  color: white;
  font-family: "Inter", sans-serif;
  align-items: center;
  padding: 3px 16px;

  background: linear-gradient(
      91.09deg,
      #fa36f8 1.51%,
      #b200e8 36.54%,
      #450fd9 101.76%
    ),
    #7fba7a;
  border-radius: 4px;
  border-color: linear-gradient(
      91.09deg,
      #fa36f8 1.51%,
      #b200e8 36.54%,
      #450fd9 101.76%
    ),
    #7fba7a;

  &__disabled {
    background-color: #cfcfcf;
  }

  &:active {
    opacity: 0.7;
    box-shadow: 0 1px #6c5dd3;
    transform: translateY(1px);
  }
}
