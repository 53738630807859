@import "../../scss/colors.scss";
@import "../../scss/repeat.scss";

.searchInput {
  display: flex;
  flex-direction: row;
  position: relative;
  font-weight: 500;
  font-family: Inter;
  width: inherit;

  &__inputArea {
    transition: background-color ease 0.2s;
    outline: none;
    border: none;
    background-color: rgba(228, 228, 228, 0.2);
    position: relative;
    z-index: 1;
    width: 100%;
    height: 56px;
    font-size: 14px;
    padding: 18px 22px;
    padding-right: 60px;
    line-height: 20px;
    border-radius: 16px;

    &:focus {
      outline: 1px solid #fff !important;
      background-color: $white;
    }

    &::placeholder {
      font-family: Inter;
      font-size: 16px;
      opacity: 0.4;
    }
  }

  &-active > &__inputArea {
    outline: 1px solid #fff !important;
    background-color: $white;
  }

  button {
    @extend %button;
    position: absolute;
    z-index: 50;
    right: 4px;
    width: 48px;
    height: 48px;
    border-radius: 12px;

    background-color: #fff;

    &:hover {
      background-color: #fff;
    }

    &:active {
      background-color: #fff;
    }
  }
}
