@import "../../scss/colors.scss";
@import "../../scss/repeat.scss";

.deleteBtns {
  width: 100%;
  column-gap: 50px;
  margin-bottom: 32px;
  margin-left: 9vw;
}
.deleteTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #11142d;
  text-align: center;
  margin-top: 32px;
  margin-bottom: 32px;
}
.deleteModal {
  position: absolute;
  top: 35vh;
  left: 30vw;
  height: auto;
  width: 40vw;
  background-color: $skyBlue;
  border-radius: 16px;
}
.ownCase {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  padding-top: 24px;
  border-bottom: #e4e4e4 solid 1px;
}
.ownLogin {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #b2b3bd;
  padding-bottom: 14px;

  border-bottom: #e4e4e4 solid 1px;
}
.ownPlace {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #808191;
}
.ownPlaceBtn {
  padding: 8px 18px;
  background: #450fd9;
  border-radius: 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  border-color: #450fd9;
  cursor: pointer;
}

.ownPlaceBtn2 {
  padding: 8px 18px;
  background: #450fd9;
  border-radius: 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  border-color: #450fd9;
  width: 200px;
  margin-right: 15px;
}

.ownPlaceBtn3 {
  padding: 8px 18px;
  background: #ffffff;
  border-radius: 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #000;
  border-color: #450fd9;
  width: 200px;
}

.globalContainerWrap {
  display: flex;
  flex-direction: "row";
  column-gap: 50px;
}
.containerOwners {
  @extend %scrollList;
  width: 25vw;
  padding: 32px;
  height: 65vh;
  border-radius: 24px;
  box-shadow: 0px 16px 16px 17px rgba(227, 230, 236, 0.86);
}
