.home {
  display: flex;
  background-color: #ffffff;
  min-height: 100vh;
}

.main_content {
  width: 100%;
  height: 90vh;
  max-height: 90vh;
  margin-top: 48px;
  padding-left: 65px;
  padding-right: 45px;
  .manage_container {
    width: 100%;
    min-width: 930px;
    min-height: 800px;
    // box-shadow: 0px 16px 16px 17px rgba(227, 230, 236, 0.86);
  }
}
.main_title {
  font-size: 24px;
  color: rgba(17, 20, 45, 1);
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
.main_subtitle {
  font-size: 48px;
  color: rgba(17, 20, 45, 1);
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}
.title_container {
  margin-bottom: 24px;
}
