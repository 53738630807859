@import "../../scss/repeat.scss";
@import "../../scss/colors.scss";
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  transition: background-color 0.4s ease;
  position: fixed;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  overflow: hidden;
  transition: z-index 0s ease;
  transition-delay: 0.4;

  &__content {
    transition: all 0.4s ease;
    transform: translateY(100vh);
    border-radius: 8px;
    position: relative;
    padding: 32px 41px;
    max-width: 90%;
    min-height: 213px;
    max-height: 90%;
    width: 80%;
    background-color: $white;
    text-align: center;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__close {
    cursor: pointer;
    border: none;
    background-color: transparent;
    position: absolute;
    top: 16px;
    right: 16px;
    &:hover {
      svg > path {
        stroke: $white;
      }
    }
  }
  &__active {
    z-index: 110 !important;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.5);
    .modal__content {
      transform: translateY(0) !important;
    }
  }
}

@media (max-width: 700px) {
  .modal {
    &__content {
      padding: 24px;
      min-height: unset;
    }
  }
}
