@import "../../scss/repeat.scss";
@import "../../scss/colors.scss";

.reviewsModal1 {
  [class~="modal__content"] {
    width: 50%;
    height: 50%;
    max-height: 820px;
    border-radius: 20px;
    padding: 48px 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }

  &__header {
    // @extend %alignedRow;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    padding: 0 32px;
    text-align: center;

    h2 {
      color: #000;

      display: flex;
      justify-content: center;
    }
  }
}

.btnRow {
  display: flex;
  flex-direction: "row";
  margin-top: 50px;
  column-gap: 50px;
}
.selector {
  width: 40%;
  height: 40px;
}

.inputFormik {
  width: 80%;
}
