.left_navigation {
  width: 256px;
  background-color: #ffffff;
  min-height: 100vh;
  padding-top: 48px;
  border-right: 1px solid #ccc;
  .logo_box {
    margin-left: 16px;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 51px;
    .logo {
      border-radius: 12px;
    }
  }
}

.main_nav_container {
  width: 216px;
  margin: 0 20px;
  position: relative;
  min-height: 80vh;
  .title {
    font-size: 12px;
    color: rgba(128, 129, 145, 1);
    margin-bottom: 16px;
    font-family: "Inter", sans-serif;
    font-weight: 500;
  }
}

.logout_container {
  position: absolute;
  bottom: 0;
  align-self: flex-end;
  flex-direction: column;
  display: flex;
  .avatar {
    width: 40px;
    height: 40px;
  }
  .admin_container {
    height: 40px;
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    justify-content: space-between;
    p {
      margin: 0;
      color: #11142d;
      font-size: 14px;
      font-family: "Inter", sans-serif;
      font-weight: 600;
    }
    .logout_link {
      text-decoration: none;
      color: #808191;
      font-size: 13px;
      font-family: "Inter", sans-serif;
      font-weight: 600;
    }
  }
}
