.screenWrapper {
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 720px;
    min-width: 400px;
}

.bgBlur {
    position: absolute;
    right: 0;
    top: 0;
    width: 40vh;
    height: 40%;
}

.signInWrapper {
    width: 500px;
    height: 700px;
    position: absolute;

    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    background-color: #fff;
    border-radius: 24px;

    display: flex;
    align-items: center;
    flex-direction: column;
}

.logoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15%;

    width: 100%;
}

.logo {
    width: 80px;
    height: 80px;
    border-radius: 4px;
}

.logInTextContainer {
    display: flex;
    font-family: "Poppins", sans-serif;
    font-weight: medium;
    font-size: 24px;

    margin-top: 10%;
    justify-content: center;
    margin-bottom: 10%;
}

.inputHeading {
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: medium;
    margin-bottom: 10px;
    margin-left: 10px;

    color: #b2b3bd;
}

.form {
    width: 70%;
}

.input {
    width: 100%;
    display: inline-block;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 18px;
    border-radius: 8px;
    margin-bottom: 10px;

    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 14px;

    border: 2px solid transparent;

    &:focus {
        border: 2px solid #b200e8;
    }
}

.button {
    color: white;
    font-family: "Inter", sans-serif;

    width: 70%;
    cursor: pointer;
    margin-top: 10%;
    background-color: #450fd9;
    border: none;
    padding: 18px;
    border-radius: 8px;

    &__disabled {
        background-color: #cfcfcf;
    }

    &:active {
        opacity: 0.7;
        box-shadow: 0 1px #6c5dd3;
        transform: translateY(1px);
    }
}
