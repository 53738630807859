@import "../../scss/colors.scss";
@import "../../scss/repeat.scss";

.usersCard {
  display: flex;
  flex-direction: row;
  color: #000;
  width: 100%;
  justify-content: space-between;
  padding-left: 32px;
  position: relative;
  width: 100%;

  padding-bottom: 20px;
  border-bottom: #e4e4e4 solid 1px;
  &__topBlock:nth-child(1) {
    position: absolute;
    left: 0;
    @extend %button;
    width: fit-content;

    svg path,
    svg {
      transition: all 0.2s ease;
    }

    &:hover {
      svg path {
        fill: #fff;
      }
    }

    &:active {
      svg path {
        fill: #fff;
      }
    }
  }

  &__topBlock:nth-child(2) {
    width: 87px;
    height: 87px;
    background-color: rgba(228, 228, 228, 0.2);
    position: relative;
    border-radius: 8px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }
  div {
    word-wrap: break-word;
  }

  div:nth-child(1) {
    width: 14.5%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    /* text/active */

    color: #11142d;
  }

  div:nth-child(2) {
    width: 8%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    /* text/active */

    color: #11142d;
  }

  div:nth-child(3) {
    width: 20%;
  }

  div:nth-child(4) {
    width: 19.3%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    /* text/active */

    color: #11142d;
  }

  div:nth-child(5) {
    width: 5.8%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    /* text/active */

    color: #11142d;
  }

  div:nth-child(6) {
    width: 8.4%;
    text-align: center;
    text-decoration: underline;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    /* text/active */

    color: #11142d;
  }

  div:nth-child(7) {
    width: 48px;

    button {
      @extend %button;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: $white;
      box-shadow: 0px 24px 43px -12px rgba(34, 60, 80, 0.4);
      margin-left: auto;

      &:hover {
        svg path {
          transition: all 0.2s ease;
          fill: $error;
        }
      }
    }
  }
}

.myClass {
  width: 8.8%;
  text-align: center;
  color: #ff754c;
  cursor: pointer;

  span {
    text-decoration: underline;
  }
}

.myClassButton {
  text-align: center;
  background-color: #fff;
  cursor: pointer;
  border-radius: 10px;
  width: 60px;
  height: 30px;
}
