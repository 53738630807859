.pageWrapper {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.rightBlock {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100wv;
}

.profitWrapper {
  margin-bottom: 60px;
}

.separator {
  overflow: hidden;
  height: 640px;
  border: 1px solid rgba($color: #000, $alpha: 0.1);
}
