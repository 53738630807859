@import "../../scss/colors.scss";
@import "../../scss/repeat.scss";

.barsButton {
  position: absolute;
  bottom: 40px;
  right: 42vw;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  padding: 9px 47px;
  background: #1e1e22;
  border-radius: 16px;
  border-color: #1e1e22;
  cursor: pointer;
}
.ownPlaceBtnCase {
  width: 100%;
  justify-content: center;
}
.ownPlaceBtn {
  padding: 8px 18px;
  background: #450fd9;
  border-radius: 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  border-color: #450fd9;
}
.users {
  width: 100%;
  padding: 32px 0;
  height: 80vh;
  max-height: 80vh;
  background-color: $white;
  border-radius: 24px;
  box-shadow: 0px 6px 35px -3px rgba(34, 60, 80, 0.2);
  @extend %scrollList;
  &__header {
    display: flex;
    flex-direction: row;
    padding: 0 32px;
    justify-content: space-between;

    [class~="searchInput"] {
      margin: 0 16px;
    }

    [class~="selectInput"] {
      width: 224px;
    }
  }

  &__infoBar {
    color: #b2b3bd;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 32px;
    border-bottom: 1px solid #e4e4e4;
    padding: 0 32px 12px 68px;

    p:nth-child(1) {
      width: 14.5%;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      /* identical to box height, or 171% */

      /* text/active */

      color: #11142d;
    }

    p:nth-child(2) {
      width: 10%;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      /* identical to box height, or 171% */

      /* text/active */

      color: #11142d;
    }

    p:nth-child(3) {
      width: 23.5%;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      /* identical to box height, or 171% */

      /* text/active */

      color: #11142d;
    }

    p:nth-child(4) {
      width: 12.3%;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      /* identical to box height, or 171% */

      /* text/active */

      color: #11142d;
    }

    p:nth-child(5) {
      width: 10.8%;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      /* identical to box height, or 171% */

      /* text/active */

      color: #11142d;
    }

    p:nth-child(6) {
      width: 5.4%;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      /* identical to box height, or 171% */

      /* text/active */

      color: #11142d;
    }

    p:nth-child(7) {
      width: 48px;
    }
  }

  &__content {
    width: 100%;
    height: inherit;

    justify-content: center;
    padding: 24px 32px 0 32px;
    overflow-x: hidden;
    [class~="usersCard"] {
      margin-bottom: 24px;
    }

    & > [class~="button"] {
      width: 200px;

      margin: 0 auto;
    }
  }

  &__loader {
    height: 56px;
    width: fit-content;
    margin: 0 auto;
  }
}
//910

.pageWrapper {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.rightBlock {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100wv;
}

.profitWrapper {
  margin-bottom: 60px;
}

.separator {
  overflow: hidden;
  height: 640px;
  border: 1px solid rgba($color: #000, $alpha: 0.1);
}
