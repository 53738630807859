.container {
    width: 300px;
    border-radius: 24px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 16px 16px 17px rgba(227, 230, 236, 0.86);
}

.heading {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: #11142d;
    margin-bottom: 10px;
}

.profitText {
    font-family: "Poppins", sans-serif;
    font-size: 72px;
    color: #450fd9;
    font-weight: 600;
}
