.wrapper {
    background-color: #ffebf6;
    max-width: 300px;
    padding: 30px 5px 8px 5px;
    border-radius: 24px;
    box-shadow: 0px 16px 35px 17px rgba(227, 230, 236, 0.86);
}

.chartContainer {
    border-radius: 16px;
    background-color: white;
    padding: 25px 0px;
    display: flex;
    flex-direction: column;
}

.heading {
    font-family: "Poppins", "serif";
    font-weight: 500;
    margin-bottom: 15px;
    margin-left: 30px;
    font-size: 18px;
}

.btnWrapper {
    width: 70%;
    margin: 0 auto;
}
