.chartWrapper {
    box-shadow: 0px 25px 25px 5px rgba(227, 230, 236, 0.86);
    border-radius: 24px;
    padding: 32px 32px 32px 0;
}

.textContentWrapper {
    padding-left: 30px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.heading {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 18px;
}

.statisticContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
}

.usersCountText {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 32px;
}

.percents {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #7fba7a;
}
