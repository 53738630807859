.main_box {
    text-decoration: none;
    width: 100%;
    height: 56px;
    display: flex;
    border-radius: 12px;
    cursor: pointer;
    &.active {
        background: linear-gradient(90deg, #fa36f8, #b200e8, #450fd9);
        color: #fff;
    }
}
.btn_link {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
    box-sizing: border-box;
    p {
        margin-left: 12px;
        font-family: "Inter", sans-serif;
        font-weight: 600;
    }
}
.text {
    color: rgba(128, 129, 145, 1);
}
.active_text {
    color: rgba(255, 255, 255, 1);
}
