html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

ul,
li {
  list-style: none;
}

a {
  text-decoration: none;
}
a:focus {
  outline: none;
}

body {
  font-family: "Mulish";
  background-color: #fff;
  font-weight: 400;
  color: #000;
}

.container {
  width: 100%;
  max-width: 1240px;
  padding: 0 15px;
  margin: 0 auto;
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 13;
}
